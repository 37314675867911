import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Slider, Box, IconButton, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { useUserSettings } from '../../../../UserSettingsContext';
import './UserProfile.css';
import UserProfileIcon from '../../../../assets/UserProfileIcon.png';

const UserProfile = ({
  filters,
  handleLanguageChange,
  handleEducationChange,
  handleExperienceRangeChange,
  educationOptions,
  availableLanguages,
  education,
  experienceRange,
  handleFileUpload,
  disabled,
  isSubmitted
}) => {
  const { userSettings } = useUserSettings();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (userSettings && userSettings.filename) {
      setSelectedFile({ name: userSettings.filename });
    } else {
      setSelectedFile(null);
    }
  }, [userSettings]);

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    handleFileUpload({ file: acceptedFiles[0] });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: isSubmitted 
  });

  const handleFileDelete = () => {
    if (!isSubmitted) { 
      setSelectedFile(null);
      handleFileUpload({ reset: true });
    }
  };

  const languageOptions = availableLanguages
    ? availableLanguages.map(lang => ({ value: lang, label: lang }))
    : [];

  return (
    <div>
      <div className="header">
        <h2 className="main-heading">User Profile Settings</h2>
        <div className="intro-section">
          <p>Update your language preferences, education, and experience to better match job listings.</p>
          <img src={UserProfileIcon} alt="User Profile Icon" className="intro-image" />
        </div>
      </div>
      <div className={`user-profile ${disabled ? 'disabled' : ''}`}>
      <div className="backend-section container-padding setting-box">
        {/* Language Preferences */}
        <div className="backend-filter-with-description">
          <div className="backend-description">
            <h3>Language Preferences</h3>
            <p>Choose the languages you are comfortable working in.</p>
          </div>
          <div className="backend-filter-item">
            <Select
              isMulti
              isClearable={false}
              isDisabled={disabled}
              value={languageOptions.filter(option => filters.languages.includes(option.value))}
              options={languageOptions}
              classNamePrefix="select"
              onChange={handleLanguageChange}
            />
          </div>
        </div>
        {/* Education Level */}
        <div className="backend-filter-with-description">
          <div className="backend-description">
            <h3>Education Level</h3>
            <p>Choose the minimum required education level for job listings.</p>
          </div>
          <div className="backend-filter-item">
            <Select
              isClearable={true}
              isDisabled={disabled}
              value={educationOptions.find(option => option.value === education)}
              options={educationOptions}
              classNamePrefix="select"
              onChange={handleEducationChange}
            />
          </div>
        </div>
        {/* Experience Range */}
        <div className="backend-filter-with-description">
          <div className="backend-description">
            <h3>Experience Range</h3>
            <p>Adjust the slider to set the experience range for job listings.</p>
          </div>
          <div className="backend-filter-item">
            <div className="backend-slider-wrapper">
              <Slider
                value={experienceRange}
                onChange={handleExperienceRangeChange}
                min={0}
                max={15}
                valueLabelDisplay="auto"
                disabled={disabled || !education}
              />
            </div>
            <div className="backend-slider-labels">
              <span>{experienceRange[0]} - {experienceRange[1]} years</span>
            </div>
          </div>
        </div>
        {/* Resume Upload */}
        <div className="backend-filter-with-description">
          <div className="backend-description">
            <h3>Resume</h3>
            <p>Upload your resume.</p>
          </div>
          <div {...getRootProps()} className="backend-filter-item">
            <input {...getInputProps()} id="fileUploadInput" style={{ display: 'none' }} disabled={isSubmitted} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
              {selectedFile ? (
                <>
                  <span>{selectedFile.name}</span>
                  <IconButton onClick={handleFileDelete} aria-label="delete" size="small" disabled={isSubmitted}>
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <Button disabled={disabled || isSubmitted}>
                  Upload File
                </Button>
              )}
            </Box>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default UserProfile;
