import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const UserSettingsContext = createContext();

export const UserSettingsProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useState(() => {
    const savedSettings = localStorage.getItem('userSettings');
    return savedSettings ? JSON.parse(savedSettings) : null;
  });
  
  const { user } = useAuth0();

  const registerUser = (userInfo) => {
    axios.post(`${process.env.REACT_APP_USER_SETTINGS_API_URL}/registerUser`, userInfo)
      .then(response => {
        console.log("User registered:", response.data);
      })
      .catch(error => {
        console.error("Error registering user:", error);
      });
  };

  useEffect(() => {
    if (user) {
      const userId = user.sub;
      axios.get(`${process.env.REACT_APP_USER_SETTINGS_API_URL}/get_user_settings?userId=${userId}`)
        .then(response => {
          if (response.data) {
            // User exists, set user settings
            setUserSettings(response.data);
            localStorage.setItem('userSettings', JSON.stringify(response.data));
          } else {
            // User doesn't exist, register user
            registerUser({
              userId: user.sub,
            });
          }
        })
        .catch(error => {
          console.error("Error fetching user settings:", error);
          // If error is due to user not found, register user
          registerUser({
            userId: user.sub,
          });
        });
    }
  }, [user]);

  return (
    <UserSettingsContext.Provider value={{ userSettings, setUserSettings }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettings = () => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }
  return context;
};
