import React, { useMemo } from 'react';
import Select from 'react-select';
import './UserProfile.css';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

function FilterPreferences({
  filters,
  handleContinentChange,
  handleCountryChange,
  handleAgencyChange,
  handleUpGradeChange,
  handleRecruitmentOptionChange,
  continents,
  availableCountries,
  groupedCountryOptions,
  agencyOptions,
  upGrades,
  recruitmentOptions,
  groupedAgencyOptions,
  disabled
}) {
  const continentOptions = continents || [];
  const countryOptions = useMemo(() => availableCountries || [], [availableCountries]);
  const upGradeOptions = upGrades || [];

  return (
    <div className={`filter-preferences ${disabled ? 'disabled' : ''}`}>
    <div className="backend-section setting-box">
        <div className="backend-filter-with-description">
        <div className="backend-description">
              <h3>Continent Filter</h3>
            <div>Select the continents where you're interested in finding jobs.</div>
          </div>
          <div className="backend-filter-item">
            <Select
              isMulti
              value={continentOptions.filter(option => filters.continents.includes(option.value))}
              isClearable={false}
              isDisabled={disabled}
              name="continents"
              options={continentOptions}
              classNamePrefix="select"
              placeholder="Filter by continent..."
              onChange={handleContinentChange}
            />
          </div>
        </div>

        <div className="backend-filter-with-description">
          <div className="backend-description">
              <h3>Country Filter</h3>
            <div>Select the countries where you're interested in finding jobs.</div>
          </div>
          <div className="backend-filter-item">
            <Select
              isMulti
              value={countryOptions.filter(option => filters.countries.includes(option.value))}
              isClearable={false}
              isDisabled={disabled}
              name="countries"
              options={groupedCountryOptions}
              classNamePrefix="select"
              placeholder="Filter by country..."
              onChange={handleCountryChange}
            />
          </div>
        </div>

        <div className="backend-filter-with-description">
        <div className="backend-description">
              <h3>Agency Filter</h3>
            <div>Select the agencies that you'd like to work for.</div>
          </div>
          <div className="backend-filter-item">
            <Select
              isMulti
              value={agencyOptions.filter(option => filters.agency.includes(option.value))}
              isClearable={false}
              isDisabled={disabled}
              name="agency"
              options={groupedAgencyOptions}
              classNamePrefix="select"
              placeholder="Filter by agency..."
              onChange={handleAgencyChange}
            />
          </div>
        </div>

        <div className="backend-filter-with-description">
        <div className="backend-description">
              <h3>Grade Level Filter</h3>
            <div>Select the grade levels suitable for you.</div>
          </div>
          <div className="backend-filter-item">
            <Select
              isMulti
              value={upGradeOptions.filter(option => filters.upGrades.includes(option.value))}
              isClearable={false}
              isDisabled={disabled}
              name="upGrade"
              options={upGradeOptions}
              classNamePrefix="select"
              placeholder="Filter by grade..."
              onChange={handleUpGradeChange}
            />
          </div>
        </div>

        <div className="backend-filter-with-description">
          <div className="backend-description">
              <h3>Recruitment Options</h3>
            <div>Choose between local and international job options.</div>
          </div>
          <div className="backend-filter-item">
          <ToggleButtonGroup
              value={[
                ...(filters.recruitmentOptions && filters.recruitmentOptions.local ? ['local'] : []),
                ...(filters.recruitmentOptions && filters.recruitmentOptions.international ? ['international'] : []),
              ]}
              onChange={handleRecruitmentOptionChange}
              disabled={disabled}
              aria-label="Recruitment Options"
              className="toggle-button-group unique-toggle-group"  // Add unique class here
            >
              <ToggleButton value="local" aria-label="Show local jobs" disabled={disabled}>Show local jobs</ToggleButton>
              <ToggleButton value="international" aria-label="Show international jobs" disabled={disabled}>Show international jobs</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
    </div>
    </div>
  );
 }  

 export default FilterPreferences;