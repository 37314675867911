import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './AccountManagement.css'; 

const AccountManagement = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
    const baseURL = 'http://localhost:4000'; // URL for the Flask app that interacts with Auth0
    const dbURL = 'http://localhost:3001'; // URL for the Flask app that interacts with your SQL DB

  const requestPasswordChange = async () => {
    try {
      await axios.post(`${baseURL}/api/requestPasswordChange`, { email: user.email });
      alert('Password change request submitted. Please check your email.');
    } catch (error) {
      console.error('Error requesting password change:', error);
    }
  };

  

  const isSocialLogin = (userSub) => {
    const socialProviders = ['google-oauth2', 'facebook', 'twitter', 'linkedin'];
    return socialProviders.some(provider => userSub.startsWith(`${provider}|`));
  };

  const deleteAccount = async () => {
    // Show a prompt asking the user to type "DELETE ACCOUNT"
    const userInput = prompt('Please type "DELETE ACCOUNT" to confirm.');

    // Check if the user input matches "DELETE ACCOUNT"
    if (userInput !== "DELETE ACCOUNT") {
        alert('Account deletion cancelled. To delete your account, you must type "DELETE ACCOUNT".');
        return; // Exit the function if the condition is not met
    }

    // Continue with the deletion process if the input matches
    if (!window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) return;

    try {
        const accessToken = await getAccessTokenSilently();

        // Step 1: Delete the account on Auth0
        await axios.post(`${baseURL}/api/deleteAccount`, { userId: user.sub }, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Step 2: Anonymize the user's data in your SQL database
        await axios.post(`${dbURL}/anonymizeUserData`, { userId: user.sub }, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        alert('Account deleted successfully.');
        logout({ returnTo: window.location.origin }); // Log the user out after account deletion
    } catch (error) {
        console.error('Error during account deletion:', error);
        alert('An error occurred during account deletion. Please try again.');
    }
};


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to view your account settings.</div>;
  }


  return (
    <div className="account-management container-padding">
        <div className="header">
            <h2 className="main-heading">Account Management</h2>
        </div>
        <div className="setting-box">
            <h3>Primary Email</h3>
            <p>{user.email}</p>
        </div>
        {
            !isSocialLogin(user.sub) && (
                <div className="setting-box">
                    <button onClick={requestPasswordChange} className="delete-button">Request Password Change</button>
                </div>
            )
        }
        <div className="setting-box">
            <button onClick={deleteAccount} className="delete-button">Delete Account</button>
        </div>
    </div>
);
};

export default AccountManagement;