import React from 'react';
import './Footer.css'; // Make sure to create a corresponding CSS file for styling

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Jobwhale. All Rights Reserved.</p>
        <nav>
          <ul className="footer-links">
            <li><a href="/terms-of-service.html">Terms of Service</a></li>
            <li><a href="/privacy.html">Privacy Policy</a></li>
            <li><a href="/about.html">About Us</a></li>
            <li><a href="mailto:admin@jobwhale.io">Contact</a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
