import React, { useState, useEffect } from 'react';
import BackendSidebar from './components/BackendSidebar';
import UserProfileAndFilters from './components/UserProfileAndFilters';
import JobAlertSettings from './components/JobAlertSettings';
import AccountManagement from './components/AccountManagement';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import './UserBackend.css';

const UserBackend = () => {
  const [selectedTab, setSelectedTab] = useState('profile'); // Default to 'profile'
  const [userSettings, setUserSettings] = useState(null); // Initialize user settings state
  const { user } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    console.log('UserBackend is being rendered');
  }, []);

  useEffect(() => {
    if (location.state?.selectedTab) {
      setSelectedTab(location.state.selectedTab);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      fetch(`http://localhost:3001/get_user_settings?userId=${user.sub}`)
        .then(response => response.json())
        .then(data => setUserSettings(data))
        .catch(error => console.error('Error fetching user settings:', error));
    }
  }, [user]);

  return (
    <div className="user-backend-container">
      <BackendSidebar selectedTab={selectedTab} onSelectTab={setSelectedTab} />
      <div className="backend-main-content">
        <div className="main-content">
          {selectedTab === 'profile' && <UserProfileAndFilters userSettings={userSettings} />}
          {selectedTab === 'jobAlert' && <JobAlertSettings />}
          {selectedTab === 'account' && <AccountManagement user={user} />}
        </div>
      </div>
    </div>
  );
};

export default UserBackend;
