  import React, { useState, useEffect } from 'react';
  import { Link, useNavigate } from 'react-router-dom';
  import { useAuth0 } from '@auth0/auth0-react';
  import './NavBar.css';
  import IconButton from '@mui/material/IconButton';
  import AccountCircleIcon from '@mui/icons-material/AccountCircle';
  import MenuIcon from '@mui/icons-material/Menu';
  import CloseIcon from '@mui/icons-material/Close';

  const MobileMenu = ({ isOpen, onClose, WORDPRESS_URL }) => (
    <div className={`mobile-menu-drawer ${isOpen ? 'open' : ''}`}>
      <IconButton onClick={onClose} className="close-menu-button">
        <CloseIcon style={{ color: '#333', fill: '#333' }} />
      </IconButton>
      <ul className="mobile-menu-items">
        <li><a href="/about.html" onClick={onClose}>About</a></li>
        <li><a href={`${WORDPRESS_URL}`} onClick={onClose}>Blog</a></li>
      </ul>
    </div>
  );

  const MobileAuthMenu = ({ isOpen, onClose, isAuthenticated, user, onLogin, onSignUp, onLogout }) => (
    <div className={`mobile-auth-menu ${isOpen ? 'open' : ''}`}>
      <IconButton onClick={onClose} className="close-menu-button">
        <CloseIcon style={{ color: '#333', fill: '#333' }} />
      </IconButton>
      <ul className="mobile-auth-items">
        {isAuthenticated ? (
          <>
            <li>
              <img src={user.picture} alt="Profile" className="user-avatar" />
              <span>{user.name}</span>
            </li>
            <li><button onClick={onLogout}>Logout</button></li>
          </>
        ) : (
          <>
            <li><button onClick={onLogin}>Login</button></li>
            <li><button onClick={onSignUp}>Sign Up</button></li>
          </>
        )}
      </ul>
    </div>
  );

  const Navbar = () => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    const navigate = useNavigate();
    const WORDPRESS_URL = process.env.REACT_APP_WORDPRESS_URL;

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [mobileAuthMenuOpen, setMobileAuthMenuOpen] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 968);

    const handleLogin = () => {
      setMobileAuthMenuOpen(false);
      loginWithRedirect();
    };

    const handleSignUp = () => {
      setMobileAuthMenuOpen(false);
      navigate('/signup');
    };

    const handleLogout = () => {
      setMobileAuthMenuOpen(false);
      logout({ returnTo: window.location.origin });
    };

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 968);
      };

      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const transitionDistance = 200;
        const progress = (scrollPosition / transitionDistance) * 100;
        const clampedProgress = Math.min(100, Math.max(0, progress));
        setScrollProgress(clampedProgress);
      };

      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const getNavbarStyle = () => {
      if (isMobile) {
        return {
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          zIndex: 1000,
        };
      } else {
        return {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: `rgba(255, 255, 255, ${scrollProgress / 100})`,
          boxShadow: scrollProgress > 0 ? `0 2px 4px rgba(0, 0, 0, ${scrollProgress / 200})` : 'none',
          zIndex: 1000,
        };
      }
    };

    const getNavLinkStyle = () => {
      const startColor = [255, 255, 255]; // white
      const endColor = [0, 123, 255]; // #007bff (same blue as signup button)
      const color = interpolateColor(startColor, endColor, scrollProgress / 100);
      return { 
        color,
        transition: 'color 0.3s ease',
        fontSize: '18px',
        fontWeight: '500',
      };
    };

    const getAuthButtonStyle = (type) => {
      const startColor = [255, 255, 255]; // white
      const endColor = type === 'login' ? [23, 162, 184] : [0, 123, 255]; // #17a2b8 or #007bff
      const color = interpolateColor(startColor, endColor, scrollProgress / 100);
      return {
        color,
        borderColor: color,
        transition: 'color 0.3s ease, border-color 0.3s ease',
        display: 'none'
      };
    };

    const getLogoStyle = () => {
      if (isMobile) {
        return {
          height: '45px',
          width: 'auto',
          objectFit: 'contain',
        };
      } else {
        return {
          opacity: 1 - scrollProgress / 100,
          position: 'absolute',
          height: '45px',
          width: 'auto',
          objectFit: 'contain',
          transition: 'opacity 0.3s ease',
          left: '50%',
          transform: 'translateX(-50%)',
        };
      }
    };

    const interpolateColor = (startColor, endColor, progress) => {
      const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * progress);
      const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * progress);
      const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * progress);
      return `rgb(${r}, ${g}, ${b})`;
    };

    const renderAuthButton = () => {
      if (isAuthenticated && user) {
        return (
          <div className="auth-button">
            <img
              src={user.picture}
              alt="Profile"
              className="user-avatar"
              onClick={() => setMobileAuthMenuOpen(true)}
            />
          </div>
        );
      } else {
        return isMobile ? (
          <IconButton 
            onClick={() => setMobileAuthMenuOpen(true)} 
            className="mobile-auth-icon"
            style={{ color: 'white', display: 'none' }}
          >
            <AccountCircleIcon fontSize='large' />
          </IconButton>
        ) : (
          <button className="login-button" onClick={loginWithRedirect} style={getAuthButtonStyle('login')}>Login</button>
        );
      }
    };

    return (
      <>
        <header className={`header-container ${isMobile ? 'mobile' : ''}`} style={getNavbarStyle()}>
          {isMobile ? (
            <div className="mobile-menu-container">
              <IconButton onClick={() => setMobileMenuOpen(true)} style={{ color: 'white' }}>
                <MenuIcon />
              </IconButton>
              <div className="logo-container mobile-logo">
                <Link to="/" style={{ display: 'inline-block' }}>
                  <img src="/duck_white.png" alt="Logo" style={getLogoStyle()} />
                </Link>
              </div>
              {renderAuthButton()}
            </div>
          ) : (
            <>
              <ul className="nav-links left">
                <li><a href="/about.html" className="nav-button" style={getNavLinkStyle()}>About</a></li>
                <li><a href={`${WORDPRESS_URL}`} className="nav-button" style={getNavLinkStyle()}>Blog</a></li>
              </ul>
              <div className="logo-container">
                <Link to="/" style={{ display: 'inline-block', height: '45px', width: '100px' }}>
                  <img 
                    src="/duck_white.png"
                    alt="White Logo" 
                    style={getLogoStyle()} 
                  />
                  <img 
                    src="/duck.png"
                    alt="Colored Logo" 
                    style={{ ...getLogoStyle(), opacity: scrollProgress / 100 }} 
                  />
                </Link>
              </div>
              <ul className="nav-links right">
                <li>{renderAuthButton()}</li>
                {!isAuthenticated && (
                  <li style={{display: 'none'}}><Link to="/signup" className="nav-button signup-button" style={getAuthButtonStyle('signup')}>Signup</Link></li>
                )}
              </ul>
            </>
          )}
        </header>

        {isMobile && (
          <>
            <MobileMenu 
              isOpen={mobileMenuOpen} 
              onClose={() => setMobileMenuOpen(false)} 
              WORDPRESS_URL={WORDPRESS_URL}
            />
            <MobileAuthMenu 
              isOpen={mobileAuthMenuOpen} 
              onClose={() => setMobileAuthMenuOpen(false)}
              isAuthenticated={isAuthenticated}
              user={user}
              onLogin={handleLogin}
              onSignUp={handleSignUp}
              onLogout={handleLogout}
            />
          </>
        )}

        {isMobile && (mobileMenuOpen || mobileAuthMenuOpen) && (
          <div 
            className="mobile-menu-overlay"
            onClick={() => {
              setMobileMenuOpen(false);
              setMobileAuthMenuOpen(false);
            }}
          />
        )}
      </>
    );
  };

  export default Navbar;