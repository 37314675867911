import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Signup from './components/Signup'; 
import UserBackend from './components/UserBackend/UserBackend';
import MainPage from './components/MainPage/MainPage';
import NotFound from './components/NotFoundPage';
import Footer from './components/Footer'; // Import the Footer component
import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useLocation, Routes, Route } from 'react-router-dom';

function App() {
  const location = useLocation(); // Get the current location
  const isUserBackend = location.pathname === '/user-backend'; 

  return (
    <>
      <CssBaseline />
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <div className="App">
        <Navbar hideMenuIcon={isUserBackend} />
        <Routes>
          {/* Removed Route for /login */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/user-backend" element={<UserBackend />} />
          <Route path="/" element={<MainPage />} />
          {/* If there are more routes, they go here */}
          <Route path="*" element={<NotFound />} /> {/* Catch-all for unmatched routes */}
        </Routes>
        <Footer /> {/* Footer added here, it will be displayed on all pages */}
      </div>
    </>
  );
}

export default App;