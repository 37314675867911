import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserSettingsProvider } from './UserSettingsContext';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Auth0Provider
        domain="dev-qjm6cmbssseo03qs.us.auth0.com"
        clientId="jGor2LpHcOoMJxvgHLqi0tJZEOnq5Osv"
        authorizationParams={{
          redirect_uri: window.location.origin + "/user-backend",
          audience: "https://dev-qjm6cmbssseo03qs.us.auth0.com/api/v2/",
          scope: "openid profile email read:users update:users"
        }}
      >
        <UserSettingsProvider>
          <App />
        </UserSettingsProvider>
      </Auth0Provider>
    </Router>
  </React.StrictMode>
);
