import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserSettings } from '../../../UserSettingsContext';
import './JobAlertSettings.css';
import JobAlertSettingsIcon from '../../../assets/JobAlertSettingsIcon.png';

const JobAlertSettings = () => {
  const { user } = useAuth0();
  const { userSettings, setUserSettings } = useUserSettings();
  const [selectedFrequency, setSelectedFrequency] = useState('Once per week');
  const [selectedWeekday, setSelectedWeekday] = useState('Monday');
  const [threshold, setThreshold] = useState(60);
  const [isEditing, setIsEditing] = useState(() => {
    return localStorage.getItem('isEditingJobAlerts') === 'true';
  });

  const frequencies = ['Once per week', 'Every two weeks', 'Once per month'];
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  useEffect(() => {
    localStorage.setItem('isEditingJobAlerts', isEditing);
  }, [isEditing]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`http://localhost:3001/get_user_settings?userId=${user.sub}`);
        const fetchedTriggerFrequency = response.data.trigger_frequency || '1';
        const fetchedThreshold = response.data.threshold || 60;

        if (fetchedTriggerFrequency.includes('.')) {
          const [frequencyCode, weekdayIndex] = fetchedTriggerFrequency.split('.');
          setSelectedWeekday(weekdays[parseInt(weekdayIndex) - 1]);
          switch (frequencyCode) {
            case '1':
              setSelectedFrequency('Once per week');
              break;
            case '2':
              setSelectedFrequency('Every two weeks');
              break;
            case '3':
              setSelectedFrequency('Once per month');
              break;
            default:
              setSelectedFrequency('Once per week');
          }
        } else {
          switch (fetchedTriggerFrequency) {
            case '1':
              setSelectedFrequency('Once per week');
              break;
            case '2':
              setSelectedFrequency('Every two weeks');
              break;
            case '3':
              setSelectedFrequency('Once per month');
              break;
            default:
              setSelectedFrequency('Once per week');
          }
        }

        setThreshold(parseInt(fetchedThreshold));
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    if (user && user.sub) {
      fetchSettings();
    }
  }, [user]);

  const handleJobAlertsChange = async (newSettings) => {
    try {
      const response = await axios.post(
        `http://localhost:3001/update_job_alerts?userId=${user.sub}`,
        newSettings,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.data && response.data.message === 'Job alert settings updated successfully!') {
        setUserSettings({
          ...userSettings,
          jobAlerts: newSettings
        });
        localStorage.setItem('userSettings', JSON.stringify(newSettings));
      }
    } catch (error) {
      console.error("Error updating job alert settings:", error);
    }
  };

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value);
    setThreshold(newValue);
  };

  const handleSubmit = () => {
    let triggerCode = '';

    switch (selectedFrequency) {
        case 'Once per week':
            triggerCode = '1';
            break;
        case 'Every two weeks':
            triggerCode = '2';
            break;
        case 'Once per month':
            triggerCode = '3';
            break;
        default:
            triggerCode = '0';
    }

    if (selectedFrequency === 'Once per week') {
        const weekdayIndex = weekdays.indexOf(selectedWeekday) + 1;
        triggerCode = `${triggerCode}.${weekdayIndex}`;
    }

    const decimalThreshold = threshold / 100;  // Convert the threshold to a decimal

    const newJobAlertSettings = {
        triggerFrequency: triggerCode,
        threshold: decimalThreshold,  // Use the decimal value for the threshold
    };

    handleJobAlertsChange(newJobAlertSettings);
    setIsEditing(false);
    localStorage.setItem('isEditingJobAlerts', 'false');
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    localStorage.setItem('isEditingJobAlerts', !isEditing);
  };

  return (
    <div className="job-alerts container-padding">
      <div className="header">
        <h2 className="main-heading">Job Alert Settings</h2>
      </div>
      <div className="intro-section">
        <div className="intro-text">
          <p>
            You can set how often you would like to receive notifications on jobs that fit your profile and your chosen filter settings. 
            You can further set how good of a match jobs need to be with your profile for JobWhale to include them into the email notification.
          </p>
        </div>
        <img src={JobAlertSettingsIcon} alt="Job Alert Settings Icon" className="intro-image" />
      </div>
      <div className={`setting-box frequency-section ${isEditing ? '' : 'disabled'}`}>
        <h3>Frequency</h3>
        <p>Choose how often you would like to receive job alerts.</p>
        <div className="btn-group">
          {frequencies.map((frequency) => (
            <button
              disabled={!isEditing}
              className={`frequency-btn ${selectedFrequency === frequency ? 'active' : ''}`}
              onClick={() => setSelectedFrequency(frequency)}
              key={frequency}
            >
              {frequency}
            </button>
          ))}
        </div>
        {selectedFrequency === 'Once per week' && (
          <div className="weekday-section">
            <h3>Weekday</h3>
            <p>Select the weekday on which you would like to receive the alert.</p>
            <div className="btn-group">
              {weekdays.map((day) => (
                <button
                  disabled={!isEditing}
                  className={`weekday-btn ${selectedWeekday === day ? 'active' : ''}`}
                  onClick={() => setSelectedWeekday(day)}
                  key={day}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={`setting-box slider-section ${isEditing ? '' : 'disabled'}`}>
        <h3>Threshold</h3>
        <p>Set a minimum threshold for job matches to be included in the alert.</p>
        <input 
          disabled={!isEditing}
          type="range" 
          min="60" 
          max="100" 
          value={threshold}
          onChange={handleSliderChange}
          className="threshold-slider"
        />
        <div className="slider-percentage">
          <span style={{left: '0%'}}>60%</span>
          <span style={{left: '50%'}}>80%</span>
          <span style={{left: '100%'}}>100%</span>
        </div>
        <div className="threshold-text">
          {threshold >= 80 ? 'Very likely to match' :
          threshold >= 70 ? 'Somewhat likely to match' :
          'Maybe match'}
        </div>
        </div>
      <button className="save-button" onClick={isEditing ? handleSubmit : toggleEdit}>
        {isEditing ? 'Save' : 'Edit'}
      </button>
    </div>
  );
};
  
export default JobAlertSettings;
